
import { defineComponent } from 'vue';
import SimulationDataHelper from '@/helpers/SimulationDataHelper';
import globals from '../../../helpers/globals';
import FormButton from '../../../components/forms/Button.vue';
import FormInput from '../../../components/forms/Input.vue';
import AccountDataService from '../../../services/AccountDataService';

export default defineComponent({
  name: 'DefinePassword',
  mixins: [
    globals,
  ],
  components: {
    FormButton,
    FormInput,
  },
  data() {
    return {
      form: {
        serverError: null as any,
        inputs: {
          password: {
            value: null,
            rule: 'required',
            error: null,
          } as {[key: string]: any},
          password_confirm: {
            value: null,
            rule: 'required|same:password',
            error: null,
          } as {[key: string]: any},
        } as {[key: string]: any},
      },
    };
  },
  created() {
    const vm = this;

    /* LOAD DATA WHEN COMPONENT IS CREATED */
    this.$store.dispatch('isLoading', true);

    this.load((data: any) => {
      vm.$store.dispatch('setSelectedSimulation', data);

      if (Object.keys(vm.$store.state.data).length === 0 || Object.keys(vm.$store.state.data.Register.data).length === 0) {
        vm.goToPreviousScreen(this, {
          simulation: vm.$route.params.simulation,
        });
      }

      vm.init();
    });
  },
  mounted() {
    /* Scroll page to top (case of mobile phones) */
    this.scrollToTop();
  },
  methods: {
    /* COMPONENT INITIALIZER */
    init() {
      /* FLAG STEP AS UNCOMPLETED */
      this.setStepAsCompleted(this, false);

      /* MOVE PROGRESS BAR TO VALUE */
      this.progress(this);

      /* SHOW SIDE AND NAV BAR IF USER FALLS DIRECTLY INTO CURRENT COMPONENT */
      this.$store.dispatch('toggleProgressBar', true);
      this.$store.dispatch('toggleSideBar', true);

      /* STOP LOADING */
      this.$store.dispatch('isLoading', false);
    },
    /* LOAD DATA THROUGH APIS */
    load(callback: any) {
      SimulationDataHelper.prepareComponentResult(
        this.$store,
        this.$route.params.simulation as string,
        callback,
      );
    },
    passwordContainsDigits() {
      return this.form.inputs.password.value && (/[0-9]/.test(this.form.inputs.password.value.trim()));
    },
    passwordContainsUppercaseLetters() {
      return this.form.inputs.password.value && (/[A-Z]/.test(this.form.inputs.password.value.trim()));
    },
    passwordContainsLowercaseLetters() {
      return this.form.inputs.password.value && (/[a-z]/.test(this.form.inputs.password.value.trim()));
    },
    passwordContains8Caracters() {
      return this.form.inputs.password.value && this.form.inputs.password.value.length >= 8;
    },
    securityLevel() {
      let level = 0;

      if (this.passwordContainsDigits()) level += 3;
      if (this.passwordContainsUppercaseLetters()) level += 3;
      if (this.passwordContainsLowercaseLetters()) level += 3;
      if (this.passwordContains8Caracters()) level += 3;

      return level;
    },
    goToNextStep(event: any) {
      const vm = this;
      event.preventDefault();
      this.$store.dispatch('isLoading', true);

      // Load recaptcha token
      this.recaptcha(this)
        .then((resp: any) => {
          vm.form.serverError = null;
          const form = {
            ...vm.$store.state.data.Register.data,
            ...{
              password: vm.form.inputs.password.value,
              password_confirmation: vm.form.inputs.password_confirm.value,
              recaptcha: vm.$store.state.recaptcha,
            },
          };

          AccountDataService.register(this.$route.params.simulation as string, form)
            .then((response: any) => {
              vm.form.serverError = null;

              this.setAccessToken(response.data.data.access_token);

              this.$store.dispatch('setDataIntoStep', { step: this.$options.name, data: { registered: true } });

              this.goToNextScreen(this, {
                simulation: vm.$route.params.simulation,
              });
            })
            .catch((e: any) => {
              this.$store.dispatch('isLoading', false);

              if (e.response.status === 422) {
                Object.keys(e.response.data).forEach((name) => {
                  if (!(name in vm.form.inputs)) {
                    vm.$router.push({
                      name: 'Register',
                      params: {
                        simulation: vm.$route.params.simulation,
                      },
                    });
                  } else {
                    [vm.form.inputs[name].error] = e.response.data[name];
                  }
                });
              }
            });
        });
    },
    disabledSubmitButton() {
      let error = false;

      Object.values(this.form.inputs).every((element: any) => {
        if ((element.value === null && element.rule.includes('required')) || element.error !== null) {
          error = true;
          return false;
        }

        return true;
      });

      if (this.securityLevel() < 12) error = true;

      return error;
    },
  },
});
